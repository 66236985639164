<template>
  <div class="row">
    <div class="col-12">
      <SectionTitle icon="fas fa-file-user" reverse :title="title" />
    </div>

    <div class="col-xs-12 col-sm-12">
      <DynamicFormList :items="contacts">
        <template #items="{ item, index }">
          <div class="col-12">
            <DynamicFormListItem
              class="bg-light-gray border-radius--light"
              :item="item"
              :no-actions="submitted"
            >
              <template #default="{ active, toggle }">
                <ContactItem
                  cols="col-md col-12"
                  :item="item"
                  :submitted="submitted || !!item.id"
                >
                  <template v-if="!!item.id && !active" #actions>
                    <div
                      class="col-md-2 mt-md-3 mt-sm-4  pb-3 pt-0  text-right"
                    >
                      <div>
                        <MenuDropDown
                          :items="[
                            {
                              label: 'Editar',
                              icon: 'fas fa-edit fa-fw text-green',
                              click: toggle,
                            },
                            {
                              visible: !item.isPrimary,
                              label: 'Eliminar',
                              icon: 'fas fa-times-circle fa-fw text-red',
                              border: 'top',
                              click: () => remove(index),
                            },
                          ]"
                          title="Acciones"
                        />
                      </div>
                    </div>
                  </template>
                </ContactItem>
                <template v-if="!submitted && active">
                  <div
                    :style="{
                      'border-top': '1px solid #E2E2E2',
                      margin: '.5rem 0',
                    }"
                  />
                  <ContactForm
                    :catalog="{ relationshipTypes: availableRelationshipTypes }"
                    :editting="active"
                    :item="item"
                    @cancel="toggle"
                    @submit="edit"
                  />
                </template>
              </template>
            </DynamicFormListItem>
          </div>
        </template>

        <template v-if="!submitted" #form="{ toggle }">
          <ContactForm
            :catalog="{ relationshipTypes: availableRelationshipTypes }"
            :item="defaultItem"
            @cancel="toggle"
            @submit="add"
          />
        </template>

        <template v-if="!submitted" #form-toggler="{ toggle }">
          <template v-if="showAddButtom">
            <div class="row text-right">
              <div class="col">
                <ActionButton
                  class="ab-btn  ab-btn-success mt-3 pl-3 pr-3 btn-block-xs-only"
                  i="fas fa-file-plus"
                  text="Añadir"
                  variant="success"
                  @click="toggle"
                />
              </div>
            </div>
          </template>
          <template v-if="!hasAnyItems">
            <ActionButton
              :class="{ invalid: validate }"
              :text="principalButtonLabel"
              type="activationButton"
              @click="toggle"
            />
          </template>
          <CustomError
            v-if="validate"
            :message="customErrorMsg"
            variant="no-border"
          />
        </template>
      </DynamicFormList>
    </div>

    <div v-if="submitted && !hasAnyItems" class="col-12">
      <div class="bg-light-gray border-radius--light py-4 text-center">
        No posee información del Contacto
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from 'SectionTitle';
import ActionButton from 'ActionButton';
import CustomError from 'custom-error';
import { DynamicFormList, DynamicFormListItem } from '@/components/common';
import MenuDropDown from 'dropdown';
import ContactForm from './ContactForm';
import ContactItem from './ContactItem';
import alert from 'utils/mixins/alert.mixin';
import { AddContact, UpdateContact, DeleteContact } from '@/api/contact.api';

export default {
  name: 'Contacts',
  mixins: [alert],
  components: {
    SectionTitle,
    CustomError,
    ContactForm,
    ContactItem,
    DynamicFormList,
    DynamicFormListItem,
    ActionButton,
    MenuDropDown,
  },
  props: {
    submitted: { type: Boolean, default: false },
    relationshipTypes: { type: Array, default: () => [] },
    contacts: { type: Array, default: () => [] },
    allowAddMore: { type: Boolean, default: true },
    validate: { type: Boolean, default: false },
    customErrorMsg: {
      type: String,
      default: 'Favor añadir la información del Contacto.',
      meta: {
        description: 'Mensaje de error encontrado',
        example: 'Información del Contacto requerida',
      },
    },
    title: {
      type: String,
      default: 'Información del Contacto',
    },
    principalButtonLabel: {
      type: String,
      default: 'Presione aquí para añadir parentesco',
    },
    limitNewContactsTo: { type: [Number, String], default: 0 },
  },
  data: () => ({ activeForms: false }),
  computed: {
    hasAnyItems() {
      return this.contacts.length > 0;
    },
    newContacts() {
      return this.contacts.filter((x) => !x.id);
    },
    hasAnyNewItems() {
      return this.newContacts.length > 0;
    },
    showAddButtom() {
      if (
        +this.limitNewContactsTo > 0 &&
        this.allowAddMore &&
        this.hasAnyNewItems
      ) {
        return this.newContacts.length < +this.limitNewContactsTo;
      }
      return this.allowAddMore && this.hasAnyItems;
    },
    defaultItem() {
      return {};
    },
    availableRelationshipTypes() {
      return this.relationshipTypes.filter(
        (x) => !this.contacts.some((i) => x.id == i.type.id)
      );
    },
  },
  methods: {
    async insert(item) {
      return await AddContact(item);
    },
    async update(item) {
      return await UpdateContact(item.id, item);
    },
    async delete(item) {
      return await DeleteContact(item.id);
    },
    add(item, reset) {
      let self = this;
      self.$emit('update:validate', false);
      if (self.submitted) {
        return;
      }
      self
        .insert(item)
        .then(function(response) {
          self.contacts.push(response.data);
          reset();
          self.ShowToast(
            'Confirmación',
            'La información del contacto ha sido guardada exitosamente.',
            'success'
          );
        })
        .catch((err) => {
          if (err.response != null)
            this.ShowToast(
              'Alerta',
              err.response.data.message || 'error',
              'error',
              5000
            );
        });
    },
    edit(item, reset) {
      let self = this;
      if (self.submitted) {
        return;
      }

      self
        .update(item)
        .then(function() {
          const index = self.contacts.findIndex((x) => x.id === item.id);
          self.contacts.splice(index, 1, item);
          reset();
        })
        .catch((err) => {
          if (err.response != null)
            this.ShowToast(
              'Alerta',
              err.response.data.message || 'error',
              'error',
              5000
            );
        });
    },
    async remove(index) {
      let self = this;
      if (this.submitted) {
        return;
      }
      const item = self.contacts[index];
      const response = await this.ShowCancelModal(
        'Confirmación',
        `¿Está seguro que desea eliminar el parentesco ${item.type.name}?`,
        'Sí',
        'No'
      );

      if (response) {
        self
          .delete(item)
          .then(function() {
            self.contacts.splice(index, 1);
            self.ShowToast(
              'Confirmación',
              'La información de contacto ha sido eliminado exitosamente.',
              'success'
            );
          })
          .catch((err) => {
            if (err.response != null)
              this.ShowToast(
                'Alerta',
                err.response.data.message || 'error',
                'error',
                5000
              );
          });
      }
    },
  },
  watch: {
    contacts: {
      handler() {
        const value = this.hasAnyItems;
        this.$emit('input', value);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
::v-deep .invalid > button {
  background-color: #f7d7da;
  border: 1px solid #c5cdcf;
}

::v-deep .text-red {
  color: #c9545e;
}
</style>
