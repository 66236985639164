var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('PageHeader',{attrs:{"page":{
            title: 'Detalles del Usuario',
            icon: 'fas fa-user-circle',
            routes: ['Inicio', 'Detalles del Usuario'],
          }},scopedSlots:_vm._u([{key:"customActions",fn:function(){return [_c('b-row',{staticClass:"h-100",attrs:{"align-h":"end","align-v":"center"}},[_c('b-button',{staticClass:"mx-2",attrs:{"variant":"outline-success"},on:{"click":function($event){return _vm.$router.push({ name: 'home' })}}},[_c('i',{staticClass:"fas fa-arrow-circle-left fa-fw"}),_vm._v(" Regresar ")]),_c('b-button',{staticClass:"mx-2",attrs:{"variant":"success"},on:{"click":_vm.update}},[_c('i',{staticClass:"fas fa-redo-alt fa-fw"}),_vm._v(" Actualizar ")])],1)]},proxy:true}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('SectionTitle',{attrs:{"has-line":"","title":"Información General"}})],1)],1),_c('div',{staticClass:"mx-3"},[_c('b-row',[_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12","md":"6"}},[_c('UiDetailDisplay',{attrs:{"label":"Nombre de Usuario:","text":_vm.viewmodel.username}})],1),_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{staticClass:"p-0 m-0",attrs:{"custom-messages":{
              required: 'El número de seguro social es requerido.',
              ssn: 'El número de seguro social no es válido.',
            },"rules":"required|ssn"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('CustomInput',{staticClass:"col-12 col-md-4 p-0 m-0",attrs:{"custom-error-msg":errors[0],"error":errors[0],"label":"Número de Seguro Social:","mask-type":"Personalssn","maxlength":"11","placeholder":"xxx-xx-xxxx"},model:{value:(_vm.socialSecurityNumber),callback:function ($$v) {_vm.socialSecurityNumber=$$v},expression:"socialSecurityNumber"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"custom-messages":{ required: 'El Nombre es requerido.' },"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('CustomInput',{attrs:{"custom-error-msg":errors[0],"error":errors[0],"label":"Nombre:"},model:{value:(_vm.model.generalInformation.firstname),callback:function ($$v) {_vm.$set(_vm.model.generalInformation, "firstname", $$v)},expression:"model.generalInformation.firstname"}})]}}])})],1),_c('b-col',{staticClass:"mt-md-0 mt-sm-4",attrs:{"cols":"12","md":"1"}},[_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('CustomInput',{attrs:{"custom-error-msg":errors[0],"error":errors[0],"label":"Inicial:","maxlength":'1'},model:{value:(_vm.model.generalInformation.middlename),callback:function ($$v) {_vm.$set(_vm.model.generalInformation, "middlename", $$v)},expression:"model.generalInformation.middlename"}})]}}])})],1),_c('b-col',{staticClass:"mt-md-0 mt-sm-4",attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"custom-messages":{
              required: 'El Apellido Paterno es requerido.',
            },"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('CustomInput',{attrs:{"custom-error-msg":errors[0],"error":errors[0],"label":"Apellido Paterno:"},model:{value:(_vm.model.generalInformation.lastpaternalname),callback:function ($$v) {_vm.$set(_vm.model.generalInformation, "lastpaternalname", $$v)},expression:"model.generalInformation.lastpaternalname"}})]}}])})],1),_c('b-col',{staticClass:"mt-md-0 mt-sm-4",attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('CustomInput',{attrs:{"custom-error-msg":errors[0],"error":errors[0],"label":"Apellido Materno:"},model:{value:(_vm.model.generalInformation.lastmaternalname),callback:function ($$v) {_vm.$set(_vm.model.generalInformation, "lastmaternalname", $$v)},expression:"model.generalInformation.lastmaternalname"}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-3"},[_c('Contacts',{attrs:{"contacts":_vm.model.contacts,"relationship-types":_vm.relationshipTypes,"title":"Información del Contacto"}})],1)],1)],1),_c('ProfileAddresses',{staticClass:"mt-5"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }