import { http } from '@/core/api';

export const getContacts = async () =>
  await http.get(`api/userprofiles/contact`);

export const AddContact = (parameters) =>
  http.post(`api/userprofiles/contact`, parameters);

export const UpdateContact = (id, parameters) =>
  http.put(`api/userprofiles/contact/${id}`, parameters);

export const DeleteContact = (id) =>
  http.delete(`api/userprofiles/contact/${id}`);
