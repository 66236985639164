<template>
  <div class="row row pl-3 pr-3  mr">
    <div class="col col-sm col-12 mt-md-2 pb-2">
      <div class="color-secondary-6 font-14">
        Nombre:
      </div>
      <div class="regular" v-text="item.name + ' ' + item.surname || '-'" />
    </div>

    <div class="col col-sm col-12 mt-md-2 pb-2">
      <div class="color-secondary-6 font-14">
        Parentesco:
      </div>
      <div class="regular" v-text="(item.type && item.type.name) || '-'" />
    </div>

    <div class="col col-sm col-12 mt-md-2 pb-2">
      <div class="color-secondary-6 font-14">
        Correo Electrónico:
      </div>
      <div class="regular word-break" v-text="item.email || '-'" />
    </div>

    <div class="col col-sm col-12 mt-md-2 pb-2">
      <div class="color-secondary-6 font-14">
        Teléfono Contacto 1:
      </div>
      <div class="regular" v-text="getPhoneMasked || '-'" />
    </div>

    <div class="col col-sm col-12 mt-md-2 pb-2">
      <div class="color-secondary-6 font-14">
        Teléfono Contacto 2:
      </div>
      <div class="regular" v-text="getPhoneTwoMasked || '-'" />
    </div>

    <div class="col col-sm col-12 mt-md-2 pb-2 d-flex flex-grow-1">
      <div
        class="text-center w-100 d-flex justify-content-center align-items-center"
      >
        <div v-if="item.isPrimary" class="primary-border">
          <span>Contacto Principal</span>
        </div>
      </div>
    </div>
    <slot name="actions" />
  </div>
</template>

<script>
import { masker, tokens } from 'ke-the-mask';

export default {
  name: 'ContactItem',
  props: {
    item: { type: Object, default: null },
    submitted: { type: Boolean, default: false },
  },
  methods: {
    async sign(validate) {
      if (this.submitted) {
        return;
      }
      validate(!this.item);
    },
  },
  computed: {
    getPhoneMasked() {
      if (!this.item.phone) return this.item.phone;
      return masker(this.item.phone, '(###)###-####', true, tokens);
    },
    getPhoneTwoMasked() {
      if (!this.item.phone2) return this.item.phone2;
      return masker(this.item.phone2, '(###)###-####', true, tokens);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/scss/variables/colors.scss';
.primary-border {
  padding: 1px 10px;
  border-radius: 3rem;
  border: 1px solid map-get($colors, 'green');
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: map-get($colors, 'gray');
    font-size: 0.625rem;
  }
}
</style>
