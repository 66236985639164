<template>
  <div id="contacts" class="col-12">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <div
        class="row bg-light-gray border-radius--light pb-3 pt-1"
        style="border-radius: 5px 5px 0px 0px"
      >
        <div class="col-12 col-sm-6 col-md-4 pt-2">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <label class="input-label">Nombre:</label>
            <input
              v-model="model.name"
              class="form-control"
              :class="{ 'was-Invalidated': errors[0] }"
              type="text"
            />
            <small v-if="errors[0]" class="errorForm">
              <i class="fa fa-times-circle mr-2" />
              El nombre es requerido.
            </small>
          </ValidationProvider>
        </div>
        <div class="col-12 col-sm-6 col-md-4 pt-2">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <label class="input-label">Apellido(s):</label>
            <input
              v-model="model.surname"
              class="form-control"
              :class="{ 'was-Invalidated': errors[0] }"
              type="text"
            />
            <small v-if="errors[0]" class="errorForm">
              <i class="fa fa-times-circle mr-2" />
              El(los) apellido(s) es(son) requerido(s).
            </small>
          </ValidationProvider>
        </div>
        <div class="col-12 col-sm-6 col-md-4 pt-2">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <label class="input-label">Parentesco:</label>
            <CustomDropDown
              v-model="model.type"
              :class="{ wasInvalidated: errors[0] }"
              :error="errors[0]"
              fieldkey="id"
              fieldname="name"
              :options="catalog.relationshipTypes"
              placeholder="Seleccione"
            />
            <small v-if="errors[0]" class="errorForm">
              <i class="fa fa-times-circle mr-2" />
              Favor seleccione parentesco.
            </small>
          </ValidationProvider>
        </div>
        <div class="col-12 col-sm-6 col-md-4 pt-2">
          <ValidationProvider
            v-slot="{ errors }"
            :custom-messages="{
              email: 'El correo electrónico no es válido.',
              required: 'El correo electrónico es requerido.',
            }"
            :rules="{
              required: true,
              email: true,
            }"
          >
            <label class="input-label">Correo Electrónico:</label>
            <input
              v-model="model.email"
              class="form-control"
              :class="{ 'was-Invalidated': errors[0] }"
              name=""
              placeholder="mail@mail.com"
              type="text"
            />
            <small v-if="errors[0]" class="errorForm">
              <i class="fa fa-times-circle mr-2" />
              <span v-text="errors[0]" />
            </small>
          </ValidationProvider>
        </div>
        <div class="col-12 col-sm-6 col-md-4 pt-2">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <label class="input-label">Teléfono Contacto 1:</label>
            <CustomInput
              v-model="model.phone"
              :class="{ wasInvalidated: errors[0] }"
              mask-type="Phone"
              :masked="false"
              placeholder="xxx-xx-xxxx"
            />
            <small v-if="errors[0]" class="errorForm">
              <i class="fa fa-times-circle mr-2" />
              El teléfono contacto 1 es requerido.
            </small>
          </ValidationProvider>
        </div>
        <div class="col-12 col-sm-6 col-md-4 pt-2">
          <label class="input-label">Teléfono Contacto 2:</label>
          <CustomInput
            v-model="model.phone2"
            mask-type="Phone"
            :masked="false"
            placeholder="xxx-xx-xxxx"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 pt-2">
          <ValidationProvider
            v-slot="{ errors }"
            tag="div"
            xclass="d-flex align-items-end h-100"
          >
            <label :style="{ 'margin-top': '15px' }" />
            <Error v-if="errors[0]" :message="errors[0]" variant="no-border" />
          </ValidationProvider>
        </div>
      </div>
      <div
        class="row bg-light-gray border-radius--light pb-3  pt-1"
        style="border-radius: 0px 0px 5px 5px"
      >
        <div
          class="col-12 col-md-2 offset-md-10 pl-3 pr-2 d-flex align-items-end justify-content-end"
        >
          <action-button
            :i="`fas fa-times-circle`"
            :responsive="true"
            variant="outline-danger m-1"
            @click="cancel"
          >
            Cancelar
          </action-button>
          <action-button
            :i="`fas fa-save`"
            :responsive="true"
            variant="success m-1"
            @click="handleSubmit(submit)"
          >
            Guardar
          </action-button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import CustomDropDown from 'custom-drop-down';
import CustomInput from 'CustomInput';
import ActionButton from 'ActionButton';
import Error from 'custom-error';

const modelInitialState = () => ({
  name: null,
  surname: null,
  type: null,
  email: null,
  phone: null,
  phone2: null,
  typeId: null,
});

export default {
  name: 'ContactForm',
  components: {
    ValidationObserver,
    ValidationProvider,
    CustomDropDown,
    CustomInput,
    ActionButton,
    Error,
  },
  props: {
    item: { type: Object, default: null },
    editting: { type: Boolean, default: false },
    catalog: {
      type: Object,
      required: true,
      default: () => ({ relationshipTypes: [] }),
    },
  },
  data: () => ({ model: modelInitialState() }),
  methods: {
    submit() {
      this.model.typeId = this.model.type.id;
      this.$emit('submit', this.model, this.cancel);
    },
    cancel() {
      this.model = modelInitialState();
      this.$refs['form'].reset();
      this.$emit('cancel');
    },
  },
  created() {
    if (this.item) {
      this.model = { ...this.model, ...this.item };
      if (this.item.type != null && this.catalog.relationshipTypes) {
        this.catalog.relationshipTypes = this.catalog.relationshipTypes.filter(
          (x) => x.id != this.item.type.id
        );
      }
    }
  },
};
</script>
<style scoped>
::v-deep .was-Invalidated {
  border: 1px solid #ff0018 !important;
}

::v-deep .wasInvalidated > div > input {
  background-color: #f7d7da !important;
  border: 1px solid #ff0018 !important;
}

::v-deep .errorForm {
  font-size: 14px;
  text-align: left;
  font: Lato, Regular;
  letter-spacing: 0;
  color: #ff5b5c;
  margin-bottom: 0;
  font-weight: 400;
}
::v-deep .errorForm > i {
  color: #ff5b5c;
  font-size: 18px;
  padding-right: 10px;
}
</style>
