<template>
  <div>
    <ValidationObserver ref="form">
      <b-row>
        <b-col cols="12">
          <PageHeader
            :page="{
              title: 'Detalles del Usuario',
              icon: 'fas fa-user-circle',
              routes: ['Inicio', 'Detalles del Usuario'],
            }"
          >
            <template #customActions>
              <b-row align-h="end" align-v="center" class="h-100">
                <b-button
                  class="mx-2"
                  variant="outline-success"
                  @click="$router.push({ name: 'home' })"
                >
                  <i class="fas fa-arrow-circle-left fa-fw" />
                  Regresar
                </b-button>

                <b-button class="mx-2" variant="success" @click="update">
                  <i class="fas fa-redo-alt fa-fw" />
                  Actualizar
                </b-button>
              </b-row>
            </template>
          </PageHeader>
        </b-col>
        <b-col cols="12">
          <SectionTitle has-line title="Información General" />
        </b-col>
      </b-row>

      <div class="mx-3">
        <b-row>
          <b-col class="mb-4" cols="12" md="6">
            <UiDetailDisplay
              label="Nombre de Usuario:"
              :text="viewmodel.username"
            />
          </b-col>
          
          <b-col class="mb-4" cols="12" md="6">
            <ValidationProvider
              class="p-0 m-0"
              v-slot="{ errors }"
              :custom-messages="{
                required: 'El número de seguro social es requerido.',
                ssn: 'El número de seguro social no es válido.',
              }"
              rules="required|ssn"
            >
              <CustomInput
                v-model="socialSecurityNumber"
                :custom-error-msg="errors[0]"
                :error="errors[0]"
                label="Número de Seguro Social:"
                mask-type="Personalssn"
                maxlength="11"
                placeholder="xxx-xx-xxxx"
                class="col-12 col-md-4 p-0 m-0"
              />
            </ValidationProvider>
          </b-col>

          <b-col class="" cols="12" md="4">
            <ValidationProvider
              v-slot="{ errors }"
              :custom-messages="{ required: 'El Nombre es requerido.' }"
              rules="required"
            >
              <CustomInput
                v-model="model.generalInformation.firstname"
                :custom-error-msg="errors[0]"
                :error="errors[0]"
                label="Nombre:"
              />
            </ValidationProvider>
          </b-col>
          <b-col class="mt-md-0 mt-sm-4" cols="12" md="1">
            <ValidationProvider v-slot="{ errors }" rules="">
              <CustomInput
                v-model="model.generalInformation.middlename"
                :custom-error-msg="errors[0]"
                :error="errors[0]"
                label="Inicial:"
                :maxlength="'1'"
              />
            </ValidationProvider>
          </b-col>
          <b-col class="mt-md-0 mt-sm-4" cols="12" md="3">
            <ValidationProvider
              v-slot="{ errors }"
              :custom-messages="{
                required: 'El Apellido Paterno es requerido.',
              }"
              rules="required"
            >
              <CustomInput
                v-model="model.generalInformation.lastpaternalname"
                :custom-error-msg="errors[0]"
                :error="errors[0]"
                label="Apellido Paterno:"
              />
            </ValidationProvider>
          </b-col>
          <b-col class="mt-md-0 mt-sm-4" cols="12" md="4">
            <ValidationProvider v-slot="{ errors }" rules="">
              <CustomInput
                v-model="model.generalInformation.lastmaternalname"
                :custom-error-msg="errors[0]"
                :error="errors[0]"
                label="Apellido Materno:"
              />
            </ValidationProvider>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col class="mt-3">
          <Contacts
            :contacts="model.contacts"
            :relationship-types="relationshipTypes"
            title="Información del Contacto"
          />
        </b-col>
      </b-row>
    </ValidationObserver>

    <ProfileAddresses class="mt-5" />
  </div>
</template>

<script>
import UiDetailDisplay from '@/components/ui/UiDetailDisplay';
import PageHeader from 'PageHeader';
import SectionTitle from 'SectionTitle';
import CustomInput from 'CustomInput';
import ProfileAddresses from '@/components/ProfileAddresses';
import { saveProfile } from '@/api/profile.api';
import alert from 'utils/mixins/alert.mixin';
import Contacts from '@/components/contacts';
import { getContacts } from '@/api/contact.api';
import { getPeiCode } from '@/api/code.api';
import {
  getSocialSecurityNumber as _getSocialSecurityNumber,
  updateSocialSecurityNumber as _updateSocialSecurityNumber,
} from '@/api/profile.api';

export default {
  name: 'Profile',
  components: {
    UiDetailDisplay,
    PageHeader,
    SectionTitle,
    CustomInput,
    ProfileAddresses,
    Contacts,
  },
  mixins: [alert],
  data: () => ({
    model: {
      generalInformation: {
        firstname: null,
        middlename: null,
        lastpaternalname: null,
        lastmaternalname: null,
      },
      contacts: [],
    },
    viewmodel: { username: null },
    socialSecurityNumber: null,
    relationshipTypes: [],
  }),
  methods: {
    async update() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }
      
      try {
        await saveProfile(this.model.generalInformation);
        this.$store.dispatch(
          '$_user/updateUserGeneralInfo',
          this.model.generalInformation
        );

        await this.updateSocialSecurityNumber(this.socialSecurityNumber);

        this.ShowToast(
          'Alerta',
          'La información ha sido actualizada exitosamente.',
          'success',
          5000
        );
      } catch(err) {
        if (err.response != null)
          this.ShowToast(
            'Alerta',
            err.response.data.message ||  err.message || 'error',
            'error',
            5000
          );
      };
    },
    async getRelationshipTypes() {
      await getPeiCode('Relationship', true).then(
        ({ data }) => (this.relationshipTypes = data)
      );
    },
    async getContacts() {
      await getContacts().then(({ data }) => (this.model.contacts = data));
    },
    async getSocialSecurityNumber() {
      const { data: ssn } = await _getSocialSecurityNumber();
      this.socialSecurityNumber = ssn;
    },
    async updateSocialSecurityNumber(ssn) {
      await _updateSocialSecurityNumber(ssn);
    },
  },
  created() {
    const profile = this.$store.state['$_user'].user.profile;
    this.viewmodel.username = profile.name;
    this.model.generalInformation = {
      firstname: profile.firstname,
      lastpaternalname: profile.lastpaternalname,
      lastmaternalname: profile.lastmaternalname,
      middlename: profile.middlename,
    };
  },
  async mounted() {
    await this.getRelationshipTypes();
    await this.getContacts();
    await this.getSocialSecurityNumber();
  },
};
</script>
<style scoped>
.input-display {
  margin-left: 0 !important;
}
</style>
