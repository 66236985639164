import { http } from '@/core/api';

export const saveProfile = (parameters) =>
  http.put(`api/userprofiles`, parameters);

export const getSocialSecurityNumber = (params) =>
  http.get(`api/userprofiles/ssn`, {
    params,
  });

export const updateSocialSecurityNumber = (ssn) =>
  http.put(`api/userprofiles/ssn/${ssn}`
);
