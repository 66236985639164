import { http } from '@/core/api';

export const getLocations = async () =>
  await http.get(`api/userprofiles/location`);

export const saveLocation = (parameters) =>
  http.post(`api/userprofiles/location`, parameters);

export const editLocation = (id, parameters) =>
  http.put(`api/userprofiles/location/${id}`, parameters);

export const getMunicipalities = async (model) => {
  return http.get('api/location/municipalities', {
    params: {
      ...model,
    },
  });
};
