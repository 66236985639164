var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12",attrs:{"id":"contacts"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"row bg-light-gray border-radius--light pb-3 pt-1",staticStyle:{"border-radius":"5px 5px 0px 0px"}},[_c('div',{staticClass:"col-12 col-sm-6 col-md-4 pt-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label"},[_vm._v("Nombre:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.name),expression:"model.name"}],staticClass:"form-control",class:{ 'was-Invalidated': errors[0] },attrs:{"type":"text"},domProps:{"value":(_vm.model.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "name", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"errorForm"},[_c('i',{staticClass:"fa fa-times-circle mr-2"}),_vm._v(" El nombre es requerido. ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"col-12 col-sm-6 col-md-4 pt-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label"},[_vm._v("Apellido(s):")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.surname),expression:"model.surname"}],staticClass:"form-control",class:{ 'was-Invalidated': errors[0] },attrs:{"type":"text"},domProps:{"value":(_vm.model.surname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "surname", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"errorForm"},[_c('i',{staticClass:"fa fa-times-circle mr-2"}),_vm._v(" El(los) apellido(s) es(son) requerido(s). ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"col-12 col-sm-6 col-md-4 pt-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label"},[_vm._v("Parentesco:")]),_c('CustomDropDown',{class:{ wasInvalidated: errors[0] },attrs:{"error":errors[0],"fieldkey":"id","fieldname":"name","options":_vm.catalog.relationshipTypes,"placeholder":"Seleccione"},model:{value:(_vm.model.type),callback:function ($$v) {_vm.$set(_vm.model, "type", $$v)},expression:"model.type"}}),(errors[0])?_c('small',{staticClass:"errorForm"},[_c('i',{staticClass:"fa fa-times-circle mr-2"}),_vm._v(" Favor seleccione parentesco. ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"col-12 col-sm-6 col-md-4 pt-2"},[_c('ValidationProvider',{attrs:{"custom-messages":{
            email: 'El correo electrónico no es válido.',
            required: 'El correo electrónico es requerido.',
          },"rules":{
            required: true,
            email: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',{staticClass:"input-label"},[_vm._v("Correo Electrónico:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.email),expression:"model.email"}],staticClass:"form-control",class:{ 'was-Invalidated': errors[0] },attrs:{"name":"","placeholder":"mail@mail.com","type":"text"},domProps:{"value":(_vm.model.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "email", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"errorForm"},[_c('i',{staticClass:"fa fa-times-circle mr-2"}),_c('span',{domProps:{"textContent":_vm._s(errors[0])}})]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"col-12 col-sm-6 col-md-4 pt-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',{staticClass:"input-label"},[_vm._v("Teléfono Contacto 1:")]),_c('CustomInput',{class:{ wasInvalidated: errors[0] },attrs:{"mask-type":"Phone","masked":false,"placeholder":"xxx-xx-xxxx"},model:{value:(_vm.model.phone),callback:function ($$v) {_vm.$set(_vm.model, "phone", $$v)},expression:"model.phone"}}),(errors[0])?_c('small',{staticClass:"errorForm"},[_c('i',{staticClass:"fa fa-times-circle mr-2"}),_vm._v(" El teléfono contacto 1 es requerido. ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"col-12 col-sm-6 col-md-4 pt-2"},[_c('label',{staticClass:"input-label"},[_vm._v("Teléfono Contacto 2:")]),_c('CustomInput',{attrs:{"mask-type":"Phone","masked":false,"placeholder":"xxx-xx-xxxx"},model:{value:(_vm.model.phone2),callback:function ($$v) {_vm.$set(_vm.model, "phone2", $$v)},expression:"model.phone2"}})],1),_c('div',{staticClass:"col-12 col-sm-6 col-md-4 pt-2"},[_c('ValidationProvider',{attrs:{"tag":"div","xclass":"d-flex align-items-end h-100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',{style:({ 'margin-top': '15px' })}),(errors[0])?_c('Error',{attrs:{"message":errors[0],"variant":"no-border"}}):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"row bg-light-gray border-radius--light pb-3  pt-1",staticStyle:{"border-radius":"0px 0px 5px 5px"}},[_c('div',{staticClass:"col-12 col-md-2 offset-md-10 pl-3 pr-2 d-flex align-items-end justify-content-end"},[_c('action-button',{attrs:{"i":"fas fa-times-circle","responsive":true,"variant":"outline-danger m-1"},on:{"click":_vm.cancel}},[_vm._v(" Cancelar ")]),_c('action-button',{attrs:{"i":"fas fa-save","responsive":true,"variant":"success m-1"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v(" Guardar ")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }