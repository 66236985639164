var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('SectionTitle',{attrs:{"icon":"fas fa-file-user","reverse":"","title":_vm.title}})],1),_c('div',{staticClass:"col-xs-12 col-sm-12"},[_c('DynamicFormList',{attrs:{"items":_vm.contacts},scopedSlots:_vm._u([{key:"items",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"col-12"},[_c('DynamicFormListItem',{staticClass:"bg-light-gray border-radius--light",attrs:{"item":item,"no-actions":_vm.submitted},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('ContactItem',{attrs:{"cols":"col-md col-12","item":item,"submitted":_vm.submitted || !!item.id},scopedSlots:_vm._u([(!!item.id && !active)?{key:"actions",fn:function(){return [_c('div',{staticClass:"col-md-2 mt-md-3 mt-sm-4  pb-3 pt-0  text-right"},[_c('div',[_c('MenuDropDown',{attrs:{"items":[
                          {
                            label: 'Editar',
                            icon: 'fas fa-edit fa-fw text-green',
                            click: toggle,
                          },
                          {
                            visible: !item.isPrimary,
                            label: 'Eliminar',
                            icon: 'fas fa-times-circle fa-fw text-red',
                            border: 'top',
                            click: function () { return _vm.remove(index); },
                          } ],"title":"Acciones"}})],1)])]},proxy:true}:null],null,true)}),(!_vm.submitted && active)?[_c('div',{style:({
                    'border-top': '1px solid #E2E2E2',
                    margin: '.5rem 0',
                  })}),_c('ContactForm',{attrs:{"catalog":{ relationshipTypes: _vm.availableRelationshipTypes },"editting":active,"item":item},on:{"cancel":toggle,"submit":_vm.edit}})]:_vm._e()]}}],null,true)})],1)]}},(!_vm.submitted)?{key:"form",fn:function(ref){
                  var toggle = ref.toggle;
return [_c('ContactForm',{attrs:{"catalog":{ relationshipTypes: _vm.availableRelationshipTypes },"item":_vm.defaultItem},on:{"cancel":toggle,"submit":_vm.add}})]}}:null,(!_vm.submitted)?{key:"form-toggler",fn:function(ref){
                  var toggle = ref.toggle;
return [(_vm.showAddButtom)?[_c('div',{staticClass:"row text-right"},[_c('div',{staticClass:"col"},[_c('ActionButton',{staticClass:"ab-btn  ab-btn-success mt-3 pl-3 pr-3 btn-block-xs-only",attrs:{"i":"fas fa-file-plus","text":"Añadir","variant":"success"},on:{"click":toggle}})],1)])]:_vm._e(),(!_vm.hasAnyItems)?[_c('ActionButton',{class:{ invalid: _vm.validate },attrs:{"text":_vm.principalButtonLabel,"type":"activationButton"},on:{"click":toggle}})]:_vm._e(),(_vm.validate)?_c('CustomError',{attrs:{"message":_vm.customErrorMsg,"variant":"no-border"}}):_vm._e()]}}:null],null,true)})],1),(_vm.submitted && !_vm.hasAnyItems)?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"bg-light-gray border-radius--light py-4 text-center"},[_vm._v(" No posee información del Contacto ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }