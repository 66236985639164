<template>
  <div>
    <div class="row">
      <div class="col">
        <SectionTitle
          icon="fas fa-map-marker-alt"
          reverse
          title="Direcciones"
        />
      </div>
    </div>
    <div v-if="!isEditing" class="mx-3">
      <div
        v-for="(item, indexLocation) in items"
        :key="`location-account-number-${indexLocation}`"
        class="row bg-secondary-13 p-3 rounded mt-3"
      >
        <div class="col-md-2 mt-md-1">
          <div class="color-secondary-6 font-14">Tipo de Dirección:</div>
          <div class="color-black font-16">
            {{ item.type.name }}
          </div>
        </div>
        <div class="col-md-3 mt-md-1 mt-sm-4">
          <div class="color-secondary-6 font-14">Dirección:</div>
          <div class="color-black font-16 font-14">
            {{ item.address }} {{ item.address2 }}
          </div>
        </div>
        <div class="col-md-2 mt-md-1 mt-sm-4">
          <div class="color-secondary-6 font-14">Municipio:</div>
          <div class="color-black font-16 font-14">
            {{ item.municipality.name }}
          </div>
        </div>
        <div class="col-md-2 mt-md-1 mt-sm-4">
          <div class="color-secondary-6 font-14">Estado:</div>
          <div class="color-black font-16 font-14">
            {{ item.state }}
          </div>
        </div>
        <div class="col-md-1 mt-md-1 mt-sm-4">
          <div class="color-secondary-6 font-14">Código Postal:</div>
          <div class="color-black font-16 font-14">
            {{ item.postalCode }}
          </div>
        </div>
        <div class="col-md-2 mt-md-1 mt-sm-4 text-right">
          <drop-down :data-item="{ item }" :items="actionItems(item)" />
        </div>
      </div>
    </div>
    <div class="mx-3">
      <validation-observer
        v-if="isEditing"
        ref="formObserver"
        novalidate
        tag="form"
      >
        <div class="row bg-secondary-3 mt-3 rounded p-3">
          <div class="col-md-4">
            <div class="form-group">
              <validation-provider v-slot="{ errors }" rules="required">
                <custom-drop-down
                  v-model="model.typeId"
                  :clearable="false"
                  custom-error-msg="El tipo de dirección es requerido."
                  :error="errors[0]"
                  fieldkey="id"
                  fieldname="name"
                  label="Tipo de Dirección:"
                  :options="getAvailableTypes"
                  placeholder="Seleccione"
                />
              </validation-provider>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <validation-provider v-slot="{ errors }" rules="required">
                <custom-input
                  v-model="model.address"
                  custom-error-msg="La dirección (línea 1) es requerida."
                  :error="errors[0]"
                  label="Dirección (Línea 1):"
                  :placeholder="'Dirección (Línea 1)'"
                />
              </validation-provider>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <validation-provider v-slot="{ errors }" rules="">
                <custom-input
                  v-model="model.address2"
                  :error="errors[0]"
                  label="Dirección (Línea 2):"
                  :placeholder="'Dirección (Línea 2)'"
                />
              </validation-provider>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <validation-provider v-slot="{ errors }" rules="required">
                <custom-drop-down
                  v-model="model.municipalityId"
                  :clearable="false"
                  custom-error-msg="El municipio es requerido."
                  :error="errors[0]"
                  fieldkey="id"
                  fieldname="name"
                  label="Municipio:"
                  :options="municipalities"
                  placeholder="Seleccione"
                />
              </validation-provider>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <validation-provider v-slot="{ errors }" rules="">
                <custom-input
                  v-model="model.state"
                  :disabled="true"
                  :error="errors[0]"
                  label="Estado:"
                  placeholder="PR"
                />
              </validation-provider>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <validation-provider v-slot="{ errors }" rules="required">
                <custom-input
                  v-model="model.postalCode"
                  custom-error-msg="El código postal es requerido."
                  :error="errors[0]"
                  label="Código Postal:"
                  mask-type="ZipcodeExt"
                  placeholder="Código Postal"
                />
              </validation-provider>
            </div>
          </div>

          <div
            class="col-12 col-md-2 offset-md-10 pl-3 pr-2 d-flex align-items-end justify-content-end"
          >
            <action-button
              :i="`fas fa-times-circle`"
              :responsive="true"
              variant="outline-danger m-1"
              @click="cancel()"
            >
              Cancelar
            </action-button>
            <action-button
              i="fas fa-save"
              :responsive="true"
              variant="success m-1"
              @click="saveEdit()"
            >
              Guardar
            </action-button>
          </div>
        </div>
      </validation-observer>
    </div>
    <div>
      <custom-callout
        v-if="items.length == 0 && !isEditing"
        class-main="py-3 mt-3"
        :has-border="false"
        :has-toggle="false"
      >
        <div class="row d-flex justify-content-center">
          <action-button
            class="border w-80 btn bg-white"
            i="fas fa-file-plus color-primary-1"
            text="Presione aquí para añadir Dirección"
            @click="addItem"
          />
        </div>
      </custom-callout>
    </div>
    <div v-if="items.length > 0 && !isEditing && canAdd" class="row text-right">
      <div class="col">
        <action-button
          i="fas fa-file-plus"
          variant="success mt-3 pl-3 pr-3 btn-block-xs-only"
          @click="addItem()"
        >
          Añadir
        </action-button>
      </div>
    </div>
  </div>
</template>

<script>
import CustomDropDown from 'custom-drop-down';
import ActionButton from 'ActionButton';
import CustomInput from 'CustomInput';
import alert from 'utils/mixins/alert.mixin';
import CustomCallout from 'custom-callout';
import DropDown from 'dropdown';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import SectionTitle from 'SectionTitle';

import {
  getLocations,
  saveLocation,
  editLocation,
  getMunicipalities,
} from '@/api/location.api';

import { getPeiCode } from '@/api/code.api';

export default {
  name: 'LocationWrapper',
  components: {
    CustomCallout,
    CustomDropDown,
    ActionButton,
    CustomInput,
    DropDown,
    ValidationObserver,
    ValidationProvider,
    SectionTitle,
  },
  mixins: [alert],
  data: () => ({
    locations: [],
    municipalities: [],
    items: [],
    editing: false,
    model: {
      id: null,
      address: null,
      address2: null,
      municipalityId: null,
      state: 'PR',
      postalCode: null,
      typeId: null,
    },
  }),
  computed: {
    isEditing() {
      return this.editing;
    },
    canAdd() {
      return this.getAvailableTypes.length > 0;
    },
    getAvailableTypes() {
      const inArray = this.items.map((s) => s.type.id);

      return this.locations.filter((x) => !inArray.includes(x.id));
    },
  },
  async mounted() {
    await getPeiCode('AddressType', true).then(
      ({ data }) => (this.locations = data)
    );

    await getMunicipalities({}).then(
      ({ data }) => (this.municipalities = data)
    );
    this.loadItems();
  },
  methods: {
    async loadItems() {
      await getLocations().then(({ data }) => (this.items = data));
    },
    async saveEdit() {
      if (this.model.id) {
        this.edit();
        return;
      }

      this.save();
    },
    async save() {
      const isValid = await this.$refs.formObserver.validate();

      if (!isValid) {
        return;
      }

      const data = { ...this.model };
      data.typeId = this.model.typeId.id;
      data.municipalityId = this.model.municipalityId.id;

      await saveLocation(data)
        .then(() => {
          this.ShowToast(
            'Alerta',
            'La Dirección ha sido guardada exitosamente.',
            'success',
            5000
          );
          this.clearForm();
          this.loadItems();
        })
        .catch((err) => {
          if (err.response != null)
            this.ShowToast(
              'Alerta',
              err.response.data.message || 'error',
              'error',
              5000
            );
        });
    },
    async edit() {
      const isValid = await this.$refs.formObserver.validate();

      if (!isValid) {
        return;
      }

      const data = { ...this.model };
      data.typeId = this.model.typeId.id;
      data.municipalityId = this.model.municipalityId.id;

      await editLocation(this.model.id, data)
        .then(() => {
          this.ShowToast(
            'Alerta',
            'La Dirección ha sido guardada exitosamente.',
            'success',
            5000
          );
          this.clearForm();
          this.loadItems();
        })
        .catch((err) => {
          if (err.response != null)
            this.ShowToast(
              'Alerta',
              err.response.data.message || 'error',
              'error',
              5000
            );
        });
    },
    editItem(item) {
      this.model = { ...item };
      this.model.typeId = item.type;
      this.model.municipalityId = item.municipality;
      this.editing = true;
    },
    addItem() {
      this.editing = true;
    },
    actionItems(item) {
      let actions = [
        {
          name: 'Editar',
          icon: 'fas fa-edit color-primary-1',
          click: () => {
            this.editItem(item);
          },
        },
      ];

      return actions;
    },
    cancel() {
      this.clearForm();
    },
    clearForm() {
      this.model = { state: 'PR' };
      this.editing = false;
    },
  },
};
</script>
